const radii = {
  none: "0",
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  "2xl": "1rem",
  "3xl": "1.5rem",
  full: "9999px",
};
const colors = {
  // ...defaultTheme.colors,
  transparent: "transparent",
  // Neutral
  n: {
    900: "#233592",
    800: "#3b5af7",
    700: "#657ffe",
    600: "#8599fe",
    500: "#3d3f48",
    400: "#696b7b",
    300: "#85889b",
    200: "#d9dfff",
    100: "#e9edff",
    75: "#F9FAFC",
    50: "#FAFBFF",
    0: "#FFFFFF",
  },
  // Blue
  b: {
    600: "#1F3D99",
    500: "#2952CC",
    400: "#3366FF",
    300: "#9DB5FF",
    200: "#D6E0FF",
    100: "#EBF0FF",
    50: "#F3F6FF",
  },
  // Green
  g: {
    600: "#317159",
    500: "#429777",
    400: "#52BD94",
    300: "#A3E6CD",
    200: "#DCF2EA",
    100: "#EEF8F4",
    50: "#F5FBF8",
  },
  // Yellow
  y: {
    600: "#66460D",
    500: "#996A13",
    400: "#FFB020",
    300: "#FFD079",
    200: "#FFDFA6",
    100: "#FFEFD2",
    50: "#FFFAF1",
  },
  // Red
  r: {
    600: "#7D2828",
    500: "#A73636",
    400: "#D14343",
    300: "#EE9191",
    200: "#F4B6B6",
    100: "#F9DADA",
    50: "#FDF4F4",
  },
  // Voilet
  v: {
    600: "#524988",
    500: "#6E62B6",
    400: "#897AE3",
    300: "#B8AFEE",
    200: "#D0CAF4",
    100: "#E7E4F9",
    50: "#F8F7FD",
  },
  // Teal
  t: {
    600: "#0F5156",
    500: "#10899E",
    400: "#25CBD6",
    300: "#7CE0E6",
    200: "#A8EAEF",
    100: "#D3F5F7",
    50: "#F2FCFD",
  },
  // Pink
  p: {
    600: "#8E3374",
    500: "#BE449B",
    400: "#ED55C2",
    300: "#F499DA",
    200: "#F8BBE7",
    100: "#FBDDF3",
    50: "#FEF5FB",
  },
  // Orange
  o: {
    600: "#85462B",
    500: "#B25E3A",
    400: "#DE7548",
    300: "#EBAC91",
    200: "#F2C8B6",
    100: "#F8E3DA",
    50: "#FDF4F4",
  },
};
const fontSizes = {
  h900: "32px",
  h800: "24px",
  h700: "20px",
  h600: "18px",
  h500: "16px",
  h400: "14px",
  h300: "12px",
  h200: "12px",
  h100: "10px",
  p300: "16px",
  p200: "14px",
  p100: "12px",
};
const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};
const fontFamilies = {
  display: "Source Sans Pro, sans-serif",
  ui: "Source Sans Pro, sans-serif",
  logo: "Poppins, sans-serif",
};

const space = {
  px: "1px",
  0: "0",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
};

const theme = {
  colors,
  fonts: {
    body: "Source Sans Pro, sans-serif",
  },
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  space,
  radii,
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  boxShadow: {
    card: "rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px",
  },
  components: {
    // ...defaultTheme.components,
    Button: {
      // ...defaultTheme.components.Button,
      intent: {
        danger: {
          color: colors.r[600],
        },
      },
      appearances: {
        primary: {
          color: colors.n[0],
          borderRadius: radii.base,
          fontSize: fontSizes.h600,
          backgroundColor: colors.b[400],
          _hover: {
            backgroundColor: colors.b[600],
          },
          _active: {
            backgroundColor: colors.b[600],
          },
        },

        disabled: {
          color: colors.n[600],
          borderRadius: radii.base,
          fontSize: fontSizes.h600,
          backgroundColor: colors.n[400],
          _hover: {
            backgroundColor: colors.n[500],
          },
          _active: {
            backgroundColor: colors.n[500],
          },
        },

        bordered: {
          color: colors.n[600],
          borderRadius: radii.base,
          border: "1px solid",
          borderColor: colors.n[500],
          _hover: {},
          _active: {
            outline: "none",
          },
        },

        secondary: {
          color: colors.n[0],
          fontSize: fontSizes.h600,
          padding: space[5],
          paddingY: space[6],
          borderRadius: radii.base,
          backgroundColor: colors.g[400],
          _hover: {
            backgroundColor: colors.g[600],
          },
          _active: {
            backgroundColor: colors.g[600],
          },
        },
      },
    },
  },
};

export default theme;
