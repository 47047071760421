import React from "react";
import { useRouter } from "./routeContext";
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthLayout } from "../pages/auth";
import { DashboardCore } from "../pages/dashboard";
import { CreateWizard } from "../pages/wizzard";

interface RouteManProps {

}

const ProtectedRoutes: React.FC<{}> = () => {
  return (
    <Switch>
       <Route exact path="/page-not-found" render={()=> <h1>Page Not found</h1>} />
      <Route exact path="/create-new" component={CreateWizard} />
      <Route path="/" component={DashboardCore} />
      <Redirect to="/" />
    </Switch>
  );
};
const OpenRoutes: React.FC<{}> = () => {
  return (
    <Switch>
       <Route exact path="/page-not-found" render={()=> <h1>Page Not found</h1>} />
      <Route path="/" component={AuthLayout} />
      <Redirect to="/" />
    </Switch>
  );
};

export const RouteMan: React.FC<RouteManProps> = (props: RouteManProps) => {
  const {
    state: { hasSession },
  } = useRouter();

  if (hasSession) {
    return <ProtectedRoutes />;
  } else {
    return <OpenRoutes />;
  }
};
