import { AppstoreOutlined, CodepenOutlined, DashboardOutlined, TeamOutlined, ToolOutlined } from "@ant-design/icons";
import { Box, Flex, Image } from "@chakra-ui/react";
import SidebarItem from "./SidebarItem";

interface ISidebarProps {
  isExpanded: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = ({
  isExpanded,
}: ISidebarProps) => {

  return (
    <Box height="100vh" position="absolute" background="n.900">
      <Flex
        height="60px"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Image
          src={
            !isExpanded
              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png"
              : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
          }
          width={!isExpanded ? "80px" : "25px"}
          height="auto"
        />
      </Flex>
      <Flex marginTop="20px" flexDirection="column">
          <SidebarItem icon={<DashboardOutlined style={{fontSize:'20px'}}/>} isExpanded={!isExpanded} name="Dashboard" href="/dashboard"/>
          <SidebarItem icon={<AppstoreOutlined style={{fontSize:'20px'}}/>} isExpanded={!isExpanded} name="Products" href="/products"/>
          <SidebarItem icon={<TeamOutlined style={{fontSize:'20px'}}/>} isExpanded={!isExpanded} name="Customers" href="/customers"/>
          <SidebarItem icon={<CodepenOutlined style={{fontSize:'20px'}}/>} isExpanded={!isExpanded} name="Orders" href="/orders"/>
          <SidebarItem icon={<ToolOutlined style={{fontSize:'20px'}}/>} isExpanded={!isExpanded} name="Settings" href="/settings"/>
      </Flex>
    </Box>
  );
};

export default Sidebar;
