import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { Button } from "../../atoms/Button";
import { FormikPasswordGroup } from "../../atoms/PasswordGroup";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useSession } from "./auth.contex";
import { toBase64 } from "../../utils/service";
interface IRegister { }

export const Register: React.FC<IRegister> = (props: IRegister) => {
  const {state:{}, actions:{register}} = useSession();
  const history = useHistory();
  return (
    <Box width="100%">
      <Formik
        initialValues={{ email: "", password: "", firstName: "", lastName: "" }}
        validationSchema={Yup.object({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          register(values,()=>{
            history.push(`/verify/register?t=${btoa(values.email)}`)
          });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Text color="n.800" marginBottom={3} fontWeight="bold">
              Register to the Platform
            </Text>
            <Text
              color="n.600"
              marginBottom={4}
              fontWeight="regular"
              fontSize="14"
            >
              Please provide the following informations.
            </Text>
            <Flex gridGap="20px">
              <FormikTextFieldGroup
                label="First Name"
                name="firstName"
              ></FormikTextFieldGroup>
              <FormikTextFieldGroup
                label="Last Name"
                name="lastName"
              ></FormikTextFieldGroup>
            </Flex>
            <FormikTextFieldGroup
              label="Email"
              name="email"
            ></FormikTextFieldGroup>
            <FormikPasswordGroup
              label="Password"
              name="password"
            ></FormikPasswordGroup>
            <Flex
              justifyContent="space-between"
              marginTop="20px"
              alignItems="center"
            >
              <Link to="/login">
                {" "}
                <Text
                  color="n.600"
                  fontWeight="regular"
                  fontSize="14"
                >
                  Already have account? Login.
                </Text>
              </Link>
              <Button htmlType={"submit"} type="primary" style={{ padding: "0 40px" }}>
                Register
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Register;
