import { Box, Text } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
import { Input } from "antd";
import { useTheme } from "@chakra-ui/react";
import { TextAreaProps } from "antd/lib/input";
const { TextArea } = Input;
interface TextAreaGroupProps extends TextAreaProps {
  label: string;
  error?: any;
}

export interface FormikTextAreaGroupProps extends TextAreaGroupProps {
  name: string;
}

export const TextAreaGroup: React.FC<TextAreaGroupProps> = ({
  label,
  error,
  ...props
}: TextAreaGroupProps) => {
  const theme: any = useTheme();
  return (
    <Box position="relative" width="100%" marginY={3}>
      <Text
        fontSize="p200"
        marginY={1}
        fontWeight={100}
        textColor="n.700"
        textTransform="capitalize"
      >
        {label}
      </Text>
      <TextArea {...props} />
      {error && (
        <Text marginTop="5px" color="red.500" fontSize="14px" fontWeight="400">
          {error}
        </Text>
      )}
    </Box>
  );
};

export const FormikTextAreaGroup: React.FC<FormikTextAreaGroupProps> = ({
  name,
  ...props
}: FormikTextAreaGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <TextAreaGroup {...props} error={error} {...field} />;
};

// backgroundColor={error ? theme.colors.r[50] : theme.colors.transparent}
// border={"1px solid"}
// borderColor={error ? theme.colors.r[500] : theme.colors.n[500]}
// fontSize={theme.fontSizes.h500}
// padding={theme.space[2]}
// paddingY={theme.space[5]}
// borderRadius={theme.radii.base}
// color={theme.colors.n[800]}
// isInvalid={error.length}
// width="100%"
// inputMode={props.type}
