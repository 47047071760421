import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { SettingProvider } from "./settings.context";
import StoreInformation from "./StoreInformation";
import { PageHeader, Tabs } from "antd";
import { Box } from "@chakra-ui/react";
import { useState } from "react";
import React from "react";
import { DomainInformation } from "./DomainInformation";
import { OrderInformation } from "./OrderSettings";
import { OtherInformation } from "./OtherSettings";

const { TabPane } = Tabs;
interface ISettingsCoreProps {}

export const SettingsCore: React.FC<ISettingsCoreProps> = (
  props: ISettingsCoreProps
) => {
  const history = useHistory();
  const location = useLocation();
  const [currentKey, setCurrentKey]:any = useState('');
  React.useEffect(()=>{
    const {pathname} = location;
    setCurrentKey(pathname.split('/').pop())
  },[location])
  return (
    <SettingProvider>
      <PageHeader
        onBack={() => {
          history.goBack();
        }}
        title={"Settings"}
        subTitle={"Store Settings"}
      />
      <Box paddingLeft="20px">
        <Tabs activeKey={currentKey} onChange={(key)=> history.push(`/settings/${key}`)}>
          <TabPane tab="Basic" key="store-info"></TabPane>
          <TabPane tab="Domain" key="domain-info"></TabPane>
          <TabPane tab="Order" key="order-info"></TabPane>
          <TabPane tab="Others" key="other"></TabPane>
        </Tabs>
        <Switch>
          <Route
            exact
            path="/settings/store-info"
            render={() => <StoreInformation store={""} />}
          />
           <Route
            exact
            path="/settings/domain-info"
            render={() => <DomainInformation />}
          />
           <Route
            exact
            path="/settings/order-info"
            render={() => <OrderInformation />}
          />
           <Route
            exact
            path="/settings/other"
            render={() => <OtherInformation />}
          />
          <Redirect to="/settings/store-info" />
        </Switch>
      </Box>
    </SettingProvider>
  );
};

export default SettingsCore;
