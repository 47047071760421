import { Box, Flex, Text } from "@chakra-ui/react";
import { Table, Tag, Space, Pagination, PageHeader, Image, Badge } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Button";
import { useCustomer } from "./customer.context";
interface ICustomerListProps {}
const columns = [
  {
    title: "Customer ID",
    dataIndex: "customerId",
    key: "customerId",
  },
  {
    title: "Total Products",
    dataIndex: "customerItems",
    key: "customerItems",
    render: (customerItems: any) => <Text>{customerItems.length} Products</Text>,
  },
  {
    title: "Customer Value in $",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (text: any) => <Text>$ {text}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "120px",
    render: (status: any, record: any) => <Tag>{status.toUpperCase()}</Tag>,
  },
];

export const CustomerList: React.FC<ICustomerListProps> = (
  props: ICustomerListProps
) => {
  const {
    state: { list },
    actions: { getAll, updateOne },
  } = useCustomer();

  React.useEffect(() => {
    getAll({});
  }, []);
  return (
    <Box>
      <PageHeader
        className="site-page-header"
        onBack={undefined}
        title="Customers"
        subTitle="List of all the Customers"
      />
      <Box padding="20px">
        <Table
          columns={columns}
          dataSource={list}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ target }: any) => {
                if (target.id === "actions") {
                  updateOne(record.id, { isActive: !record.isActive }, () => {
                    getAll({});
                  });
                }
              },
            };
          }}
        />
        ;
        <Flex justifyContent="end">
          {/* <Pagination defaultCurrent={1} total={50} /> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default CustomerList;
