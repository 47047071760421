import { Box, Flex, Text } from "@chakra-ui/react";
import { PageHeader, Tag } from "antd";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { Button } from "../../atoms/Button";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import { useSession } from "../auth/auth.contex";
import { useStores } from "../dashboard/store.context";

interface ICreateProps {}

export const CreateStore: React.FC<ICreateProps> = (props: ICreateProps) => {
  const {
    actions: { createOne, getStoreNameSuggestions },
  } = useStores();
  const {
    actions: { refreshStorage },
  } = useSession();
  const [initialValue, setInitialValue] = useState({
    storeTitle: "",
    storeUniqueName: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();

  React.useEffect(() => {
    getStoreNameSuggestions((data) => {
      setSuggestions(data.stores.names);
    });
  }, []);
  return (
    <Box>
      <PageHeader
        onBack={undefined}
        title={
          <Text fontSize="16px" color="n.900" marginTop="4px">
            Create a New Store
          </Text>
        }
      />
      <Box padding="20px">
        <Text fontSize="14px" color="n.400">
          Please provide the following information to create a new store
        </Text>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          validationSchema={Yup.object({
            storeTitle: Yup.string().required("Required"),
            storeUniqueName: Yup.string()
              .required("Required")
              .min(8, "Must be minimum 8 letters")
              .max(24, "Must be less than 24 letters")
              .matches(
                /^[a-z]+$/,
                "Only alphabets are allowed for this field "
              ),
          })}
          onSubmit={(values, actions) => {
            createOne(values, () => {
              refreshStorage();
              history.push("/");
            });
          }}
        >
          {({ values, setFieldValue }) => (
            <Box marginTop="40px">
              <Form>
                <FormikTextFieldGroup
                  label="Store Name"
                  name="storeTitle"
                ></FormikTextFieldGroup>

                <FormikTextFieldGroup
                  label="Store Unique URL"
                  name="storeUniqueName"
                  addonBefore="https://"
                  suffix=".cannatrade.shop"
                  maxLength={16}
                ></FormikTextFieldGroup>

                <Box>
                  <Text fontSize="14px" color="n.400">
                    Suggestions:
                  </Text>
                  <Flex  flexWrap="wrap">
                    {suggestions.map((s) => (
                      <Tag
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#FAFBFF",
                          marginTop:"10px"
                        }}
                        onClick={()=>setInitialValue({...initialValue, storeUniqueName: s})}
                      >
                        {s}
                      </Tag>
                    ))}
                  </Flex>
                </Box>
                <Flex justifyContent="end" marginTop="20px" gridGap="20px">
                  <Button
                    htmlType="button"
                    type="ghost"
                    style={{ padding: "0 40px" }}
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    style={{ padding: "0 40px" }}
                  >
                    Create
                  </Button>
                </Flex>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CreateStore;
