import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { Button } from "../../atoms/Button";
import { FormikPasswordGroup } from "../../atoms/PasswordGroup";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useSession } from "./auth.contex";
interface ILogin {}

export const Login: React.FC<ILogin> = (props: ILogin) => {
  const {actions:{login}} = useSession();
  return (
    <Box width="100%">
      <Formik
        initialValues={{ email: "", password: "", type: "local" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          login(values, ()=>{

          })
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Text color="n.800" marginBottom={3} fontWeight="bold">
              Login to the Platform
            </Text>
            <Text
              color="n.600"
              marginBottom={4}
              fontWeight="regular"
              fontSize="14"
            >
              Please provide your email and password.
            </Text>
            <FormikTextFieldGroup
              label="Email"
              name="email"
            ></FormikTextFieldGroup>
            <FormikPasswordGroup
              label="Password"
              name="password"
            ></FormikPasswordGroup>
            <Flex
              justifyContent="space-between"
              marginTop="20px"
              alignItems="center"
            >
              <Link to="/register">
                {" "}
                <Text
                  color="n.600"
                  fontWeight="regular"
                  fontSize="14"
                >
                  Don't have a account? Register.
                </Text>
              </Link>
              <Button type="primary" htmlType="submit" style={{ padding: "0 40px" }}>
                Login
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
