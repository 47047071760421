/**
 * @author Ramachandran Gunasekeran
 * @email ramachandrangunasekeran@gmail.com
 * @create date 2019-11-13 16:00:14
 * @modify date 2019-11-13 16:00:14
 * @desc [description]
 */
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import IsBase64 from 'is-base64';
/**
 * Function returns the First Error of the Array or Object.
 * @param {Object} error The Object of Error's returned from API Service.
 */
export const parseInputErrors = (error) => {
	if (!error) {
		return;
	}
	const firstError = error[0];
	// eslint-disable-next-line consistent-return
	return `${firstError.param.toUpperCase()}: ${firstError.msg}`;
};
/**
 * Function appending QS to the provided URL.
 * @param {String} url The URL to which the QS needed to be added.
 * @param {Object} params The QS.
 */
export const applyQueryParams = (url, params = {}) => {
	if (isEmpty(params)) {
		return url;
	}
	const queryParams = queryString.stringify(params);
	return `${url}?${queryParams}`;
};
/**
 * Function returns the QueryString parsed to Object.
 * @param {String} qs QueryString from the URL.
 */
export const parseQueryStrings = (qs) => queryString.parse(qs);

export const constructURL = (uri = '/jobs', displayName, jobId) =>
	`${uri}/${urlSlug.convert(displayName)}-${jobId}`;

export const isBase64Image = (str) => {
	return IsBase64(str, { allowMime: true });
};

export const beforeUpload = (file) => {
	return new Promise((resolve, reject) => {
		const isJpgOrPng =
			file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			reject('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 <= 5;
		if (!isLt2M) {
			reject('Image must smaller than 5MB!');
		}
		resolve(true);
	});
};

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
