import { Text, Flex, Box } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

interface ISidebarItemProps {
  name: string;
  icon: React.ReactNode;
  isExpanded: boolean;
  href:string;
}


export const SidebarItem: React.FC<ISidebarItemProps> = ({
  icon,
  name,
  isExpanded,
  href
}: ISidebarItemProps) => {
  const {pathname} = useLocation()
  const isSelected = href.startsWith(pathname)|| false;
  return (
    <Link to={href} >
      <Flex
        cursor="pointer"
        width="100%"
        padding="10px 20px"
        justifyContent={isExpanded ? "flex-start" : "center"}
        alignItems="center"
        background={isSelected?"#5f5fc4":""}
        position="relative"
      >
        {isSelected &&<Box background="#001970" width="4px" height="100%" position="absolute" top="0" left="0"></Box>}
        {icon}
        {isExpanded && (
          <Text marginLeft="10px" marginTop="2px" fontSize="14px" fontWeight="400">
            {name}
          </Text>
        )}
      </Flex>
    </Link>
  );
};

export default SidebarItem;
