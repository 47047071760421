import { Box, Flex, Text } from "@chakra-ui/layout";
import { Avatar, Dropdown, Menu } from "antd";
import {
  DownOutlined,
  UserOutlined,
  PlusOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSession } from "../../pages/auth/auth.contex";
import { useStores } from "../../pages/dashboard/store.context";
import React from "react";
interface IHeadersProps {
  onExpand: (state: boolean) => void;
  isExpanded: boolean;
}

const returnExpandIcon = (state: boolean) => {
  return state ? (
    <MenuFoldOutlined style={{ color: "black" }} />
  ) : (
    <MenuUnfoldOutlined style={{ color: "black" }} />
  );
};

export const Header: React.FC<IHeadersProps> = ({
  isExpanded,
  onExpand,
}: IHeadersProps) => {
  const {
    state: { profile, currentStore: currentStoreId },
    actions: { logout, refreshStorage },
  } = useSession();

  const {
    actions: { getAllStores, getOneStore, updateCurrentStore },
    state: { stores, currentStore },
  } = useStores();
  React.useEffect(() => {
    getAllStores();
    getOneStore(currentStoreId);
  }, []);

  const storeDropDown = () => {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Text
          fontSize="p200"
          fontWeight={100}
          textColor="n.500"
          textTransform="capitalize"
          marginRight="10px"
        >
          Current Store
        </Text>
        <Dropdown.Button
          icon={<DownOutlined />}
          onClick={() => {}}
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key !== "new") {
                  updateCurrentStore(key, () => {
                    refreshStorage();
                  });
                }
              }}
            >
              {stores.map((store: any) => (
                <Menu.Item key={store.id} icon={<UserOutlined />}>
                  <Text
                    fontSize="p200"
                    fontWeight={400}
                    textColor="n.900"
                    textTransform="capitalize"
                    marginLeft="10px"
                  >
                    {store.storeTitle}
                  </Text>
                </Menu.Item>
              ))}

              <Menu.Divider />
              <Menu.Item key="new" icon={<PlusOutlined />}>
                <Link to="/create-new"> Create New Store</Link>
              </Menu.Item>
            </Menu>
          }
        >
          <Link to="/settings">
            {" "}
            <Text
              fontSize="p200"
              fontWeight={600}
              textColor="n.900"
              textTransform="capitalize"
              marginLeft="10px"
            >
              {(currentStore && currentStore.storeTitle) || "N/A"}
            </Text>
          </Link>
        </Dropdown.Button>
      </Flex>
    );
  };

  return (
    <Flex
      justifyContent="space-between"
      height="100%"
      flexDirection="row"
      alignItems="center"
      paddingRight="40px"
    >
      <Flex cursor="pointer" marginTop="-5px" ml="10px">
        <Box onClick={() => onExpand(!isExpanded)}>
          {" "}
          {returnExpandIcon(isExpanded)}
        </Box>

        {currentStore && (
          <Box>
            <Text fontSize="p100" fontWeight={400} textColor="n.900">
              Store Url:
              <Text as="span" marginLeft="20px" fontWeight={600}>
                <a href={`https://${currentStore.storeUrl}`}>
                  https://{currentStore.storeUrl}
                </a>
              </Text>
            </Text>
          </Box>
        )}
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        {storeDropDown()}
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="2" icon={<UserOutlined />}>
                <Link to="/profile"> Profile</Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="3"
                onClick={() => logout()}
                icon={<LogoutOutlined />}
              >
                Log Out
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Flex cursor="pointer" marginLeft="20px" alignItems="center">
            <Avatar></Avatar>
            <Text
              fontSize="p100"
              fontWeight={600}
              textColor="n.900"
              textTransform="capitalize"
              marginLeft="10px"
            >
              {profile.firstName || "N/A"} {profile.lastName || "N/A"}
            </Text>
            <DownOutlined
              style={{ color: "black", marginTop: "3px", marginLeft: "5px" }}
            />
          </Flex>
        </Dropdown>
      </Flex>
    </Flex>
  );
};

export default Header;
