import { message } from "antd";
import { APIService } from "./service/request.service";

type IUploadOptions = {
    folder: string;
    maxFileSize: number | null;
}

const ImagetoBase64 = (file: any) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = (e: any) => res(e.target.result);
        reader.onerror = e => rej(e);
        reader.readAsDataURL(file);
    });
}


const getFileName = (file: any) => {
    const extension = file.name.split(".").pop();
    return `${Math.random().toString(36).substr(2, 15)}.${extension}`;
};

const uploadImage = (file: any, uploadOptions: IUploadOptions = { folder: '/products/', maxFileSize: null }) => {
    return new Promise(async (resolve, reject) => {
        try {
            message.loading({ content: "Uploading Image", key: "image",duration:null })
            //Get Signed URL
            const signedRes = await APIService.get("/upload/signed-url");
            const { endpoint: endPoint } = signedRes.data;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", getFileName(file));
            formData.append("folder", uploadOptions.folder);
            formData.append("publicKey", endPoint.publicKey);
            formData.append("signature", endPoint.hash || "");
            formData.append("expire", endPoint.expire || 0);
            formData.append("token", endPoint.token);
            const res = await fetch("https://upload.imagekit.io/api/v1/files/upload", {
                method: "POST",
                body: formData,
            });
            const uploadData = await res.json();
            message.destroy('image')
            resolve(uploadData);
        } catch (e) {
            message.destroy('image')
            message.error({ content: "Error uploading image", key: "image"})
        }
    })
}
export { ImagetoBase64, uploadImage }