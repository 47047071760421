import { Box, Grid } from "@chakra-ui/react";
import * as React from "react";
import { Header } from "../../molecules/header";
import { Sidebar } from "../../molecules/sidebar";
import { Switch, Route, Redirect } from "react-router";
import Dashboard from "./Dashboard";
import { ProductCore } from "../products";
import { CustomerCore } from "../customers";
import { OrderCore } from "../orders";
import { SettingsCore } from "../settings";
import { useSession } from "../auth/auth.contex";
import { StoreProvider } from "./store.context";
interface IDashbaord {}

export const DashboardCore: React.FC<IDashbaord> = (props: IDashbaord) => {
  const [expand, setExpand] = React.useState(true);
  const { state } = useSession();

  if (!state.currentStore) {
    return <Redirect to="/create-new" />;
  }
  return (
    <StoreProvider>
      <Grid
        gridTemplateColumns={`${expand ? "60" : "180"}px 1fr`}
        gridTemplateRows="60px 1fr"
        gridTemplateAreas={`"a b" "a c"`}
        width="100vw"
        height="100vh"
        overflow="scroll"
      >
        <Box gridArea="a" borderRight="0.5px solid #efefe0">
          <Sidebar isExpanded={expand} />
        </Box>
        <Box gridArea="b" borderBottom="0.5px solid #efefe0">
          <Header isExpanded={expand} onExpand={(state) => setExpand(state)} />
        </Box>
        <Box gridArea="c">
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/customers" component={CustomerCore} />
            <Route path="/products" component={ProductCore} />
            <Route path="/orders" component={OrderCore} />
            <Route path="/settings" component={SettingsCore} />
            <Redirect to="/dashboard" />
          </Switch>
        </Box>
      </Grid>
    </StoreProvider>
  );
};

export default DashboardCore;
