import { useReducer } from "react";
import { container } from "../../utils/container";
import { APIService } from "../../utils/service/request.service";
import { message } from "antd";
import { CookieStorage, LocalStorage } from "../../utils/storage";

const ApiRoutes = {
    base: "/stores",
    owners: "/owners"
};

export type StateType = {
    currentStore: null
    stores: any[]
};

const initialState: StateType = {
    currentStore: null,
    stores: []
};

const reducer = (
    state: StateType,
    action: {
        type: string;
        payload?: any;
    }
) => {
    const { type, payload } = action;
    switch (type) {
        case "CREATE_STORE_SUCCESS":
        case "ONE_STORE_SUCCESS":
            return {
                ...state,
                currentStore: payload
            }
        case "STORE_LIST_SUCCESS":
            return {
                ...state,
                stores: payload
            }
        default:
            return state;
    }
};

export const { useContext: useStores, Provider: StoreProvider } = container(
    () => {
        const [state, dispatch] = useReducer(reducer, {
            ...initialState,
        });

        const createOne = (data: any, callback?: (data: any) => void) => {
            APIService.post(ApiRoutes.base, data)
                .then((res: any) => {
                    const { store } = res.data;
                    dispatch({ type: "CREATE_STORE_SUCCESS", payload: store });
                    LocalStorage.write("current_store", store.id);
                    getAllStores();
                    if (typeof callback === "function") {
                        callback(store);
                    }
                })
                .catch((error: any) => {
                    if (typeof error === "object" && error.status) {
                        message.error(error.message);
                    }
                });
        };


        const getAllStores = (callback?: (data: any) => void) => {
            APIService.get(ApiRoutes.base)
                .then((res: any) => {
                    const { stores } = res.data;

                    dispatch({ type: "STORE_LIST_SUCCESS", payload: stores });
                    if (typeof callback === "function") {
                        callback({});
                    }
                })
                .catch((error: any) => {
                    if (typeof error === "object" && error.status) {
                        message.error(error.message);
                    }
                });
        };

        const getOneStore = (id: string, callback?: (data: any) => void) => {
            APIService.get(`${ApiRoutes.base}/${id}`)
                .then((res: any) => {
                    const { store } = res.data;
                    dispatch({ type: "ONE_STORE_SUCCESS", payload: store });
                    if (typeof callback === "function") {
                        callback({});
                    }
                })
                .catch((error: any) => {
                    if (typeof error === "object" && error.status) {
                        message.error(error.message);
                    }
                });
        };


        const updateCurrentStore = (currentStore: string, callback?: (data: any) => void) => {
            LocalStorage.write("current_store", currentStore);
            APIService.put(`${ApiRoutes.owners}`, { currentStore })
                .then((res: any) => {
                    getOneStore(currentStore)
                    if (typeof callback === "function") {
                        callback({});
                    }
                })
                .catch((error: any) => {
                    if (typeof error === "object" && error.status) {
                        message.error(error.message);
                    }
                });
        };

        const getStoreNameSuggestions = (callback?: (data: any) => void) => {

            APIService.get(`${ApiRoutes.base}/unique-name/suggestions`)
                .then((res: any) => {
                    if (typeof callback === "function") {
                        callback(res.data);
                    }
                })
                .catch((error: any) => {
                    if (typeof error === "object" && error.status) {
                        message.error(error.message);
                    }
                });
        };

        return {
            state,
            actions: {
                getOneStore,
                createOne,
                getAllStores,
                updateCurrentStore,
                getStoreNameSuggestions
            },
        };
    }
);
