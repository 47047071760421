import { useReducer } from "react";
import { container } from "../../utils/container";
import { APIService } from "../../utils/service/request.service";
import { message } from "antd";
import { CookieStorage, LocalStorage } from "../../utils/storage";

const ApiRoutes = {
  base: "/orders",
};

export type StateType = {
  list: any[],
  currentOrder: any
};

const initialState: StateType = {
  list: [],
  currentOrder: null
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_ALL_ORDERS":
      return {
        ...state,
        list: payload
      };
    case "GET_ONE_SUCCESS":
      return {
        ...state,
        currentOrder: payload
      };
    case "CLEAR":
      return {
        ...state,
        currentOrder: null
      };
    default:
      return state;
  }
};

export const { useContext: useOrder, Provider: OrderProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    const create = (data: any, callback?: (data: any) => void) => {
      message.loading({ content: 'creating...', key: "orders" })
      APIService.post(ApiRoutes.base, data)
        .then(({ data }: any) => {
          dispatch({ type: "GET_ONE_SUCCESS", payload: data.order });
          message.success({ content: "Created successfully", key: "orders" })
          if (typeof callback === "function") {
            callback(data.order);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getAll = (filter: any, callback?: (data: any) => void) => {
      APIService.get(ApiRoutes.base)
        .then(({ data }: any) => {
          dispatch({ type: "GET_ALL_ORDERS", payload: data.orders });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const getOne = (id: string, callback?: (data: any) => void) => {
      message.loading({ content: 'loading...', key: "orders" })
      APIService.get(`${ApiRoutes.base}/${id}`)
        .then(({ data }: any) => {

          dispatch({ type: "GET_ONE_SUCCESS", payload: data.order });
          if (typeof callback === "function") {
            callback(data.order);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (id: string, data: any, callback?: (data: any) => void) => {
      message.loading({ content: 'updating...', key: "orders" })
      APIService.put(`${ApiRoutes.base}/${id}`, data)
        .then(({ data }: any) => {
          dispatch({ type: "GET_ONE_SUCCESS", payload: data.order });
          message.success({ content: "updates successfully", key: "orders" })
          if (typeof callback === "function") {
            callback(data.order);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        create,
        getAll,
        getOne,
        updateOne,
        clearCurrent: () => dispatch({ type: "CLEAR", payload: {} })
      },
    };
  }
);
