/**
 * @author Ramachandran Gunasekeran
 * @email ramachandrangunasekeran@gmail.com
 * @create date 2019-11-13 15:02:43
 * @modify date 2019-11-13 16:28:18
 * @desc [description]
 */
// import { clearStorage } from '../helpers/session.helper';
// import { SESSION } from '../routes/inapp.routes';
import { parseInputErrors } from "./index";
/**
 * Promise returns response object.
 * @param {Object} response The response object from Fetch API.
 */
export const handleHeaders = (response) =>
  new Promise((resolve, reject) => {
    if (!response) {
      reject(new Error("No response returned from fetch."));
    }
    resolve(response);
  });
/**
 * Promise returns Errors checking the API Status else resolve ok.
 * @param {Object} response The response object from Fetch API.
 */
export const handleErrors = (response) =>
  new Promise((resolve, reject) => {
    if (!response) {
      reject(new Error("No response returned from fetch."));
      return;
    }
    if (response.ok) {
      resolve(response);
      return;
    }
    if (response.status === 401) {
      // clearStorage();
      if (window.location.pathname !== "/login") {
        // window.location = '/login';
      } else {
        reject({
          status: 401,
          message: "Invalid Login Credentials",
        });
      }
    }
    response
      .json()
      .then((json) => {
        console.log(json)
        switch (response.status) {
          case 422:
            return reject({
              status: response.status,
              message: json.error.msg,
            });
          case 400:
            return reject({
              status: 400,
              message: json.error.message,
            });
          case 404:
            return reject({
              status: 404,
              message: json.message,
            });
          case 403:
            return reject({
              status: 404,
              message: json.message,
            });
          case 409:
            return reject({
              status: response.status,
              message: json.error.msg || "",
            });
          case 503:
          case 500:
          case 501:
            return reject({
              status: response.status,
              message: "Internal Server Error.",
            });
          default:
            return reject({
              status: null,
              message: response.statusText,
            });
        }
      })
      .catch(() =>
        reject({
          status: 500,
          message: "Response not JSON",
        })
      );
  });
/**
 * Promise returns the JSON Body as a promise from the API response.
 * @param {Object} response The response object from Fetch API.
 */
export const getResponseBody = (response) => {
  const bodyIsEmpty = response.status === 204;
  if (bodyIsEmpty) {
    return Promise.resolve();
  }
  return response.json();
};
