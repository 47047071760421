import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { Button } from "../../atoms/Button";
import { FormikPasswordGroup } from "../../atoms/PasswordGroup";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
interface IForgotPassword { }

export const ForgotPassword: React.FC<IForgotPassword> = (props: IForgotPassword) => {
    return (
        <Box width="100%">
            <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email("Invalid email address")
                        .required("Required")
                })}
                onSubmit={(values, actions) => { }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Text color="n.800" marginBottom={3} fontWeight="bold">
                            Forgot Password
                        </Text>
                        <Text
                            color="n.600"
                            marginBottom={4}
                            fontWeight="regular"
                            fontSize="14"
                        >
                            Please enter your registered email address to send a OTP.
                        </Text>
                        <FormikTextFieldGroup
                            label="Email"
                            name="email"
                        ></FormikTextFieldGroup>
                       
                        <Flex justifyContent="end" marginTop="20px">
                            <Button type="primary" style={{ padding: "0 40px" }}>
                                Send OTP
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ForgotPassword;
