import { useReducer } from "react";
import { container } from "../../utils/container";
import { APIService } from "../../utils/service/request.service";
import { message } from "antd";
import { CookieStorage, LocalStorage } from "../../utils/storage";

const ApiRoutes = {
  register: "/owners/register",
  resendOTP: "/auth/resend-code",
  verifyOTP: "/auth/verify-otp",
  login: "/auth/login",
};

export type StateType = {
  profile: any;
  currentStore: any;
};

const initialState: StateType = {
  profile:null,
  currentStore:null
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "LOGOUT":
      CookieStorage.clear();
      LocalStorage.clear();
      window.location.reload();
      return {
        ...state,
        hasSession: false,
      };
      case "REFRESH":
        return {
          ...state,
          profile: LocalStorage.read('profile'),
          currentStore:LocalStorage.read('current_store')
        };
    default:
      return state;
  }
};

export const { useContext: useSession, Provider: SessionProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
      profile: LocalStorage.read('profile'),
      currentStore:LocalStorage.read('current_store')
    });

    const register = (data: any, callback?: (data: any) => void) => {
      APIService.post(ApiRoutes.register, data)
        .then((res: any) => {
          // dispatch({ type: "REGISTER_SUCCESS", payload: {} });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const login = (data: any, callback?: (data: any) => void) => {
      APIService.post(ApiRoutes.login, data)
        .then((res: any) => {
          const response: any = res.data;
          const { user } = response;
          CookieStorage.write("token", user.token, 2, "/");
          LocalStorage.write("profile", user.user);
          LocalStorage.write("current_store", user.user.currentStore);
          window.location.reload();
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const resendOTP = (data: any, callback?: (data: any) => void) => {
      APIService.post(ApiRoutes.resendOTP, data)
        .then((res: any) => {
          message.success("OTP sent successfully.");
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const verifyOTP = (
      data: any,
      type: "register" | "reset",
      callback?: (data: any) => void
    ) => {
      APIService.post(ApiRoutes.verifyOTP, data)
        .then((res: any) => {
          // message.success("OTP sent successfully.")
          const response: any = res.data;
          if (type === "register") {
            const { user } = response;
            CookieStorage.write("token", user.token, 2, "/");
            LocalStorage.write("profile", user.user);
            LocalStorage.write("current_store", user.user.currentStore);
            window.location.reload();
          }
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        register,
        resendOTP,
        verifyOTP,
        login,
        logout: () => {
          dispatch({ type: "LOGOUT" });
        },
        refreshStorage: ()=>{
          dispatch({ type: "REFRESH" });
        }
      },
    };
  }
);
