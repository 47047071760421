import { Box, Grid, Image } from "@chakra-ui/react";
import { StoreProvider } from "../dashboard/store.context";
import CreateStore from "./Create";

interface ICreateWizard {}

export const CreateWizard: React.FC<ICreateWizard> = (props: ICreateWizard) => {
  return (
    <StoreProvider>
    <Grid
      width="100vw"
      height="100vh"
      gridAutoFlow="column"
      gridTemplateColumns="0.3fr 0.7fr"
    >
      <Box  width="100%" height="100%">
          <CreateStore/>
      </Box>
      <Box
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="none"
        backgroundImage="https://images.unsplash.com/photo-1457573557536-6b4b6ca9a05e"
      ></Box>
    </Grid>
    </StoreProvider>
  );
};

export default CreateWizard;
