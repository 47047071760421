import { Box, Flex, Text } from "@chakra-ui/react";
import { Table, Tag, Space, Pagination, PageHeader, Image, Badge } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Button";
import { useOrder } from "./order.context";
interface IOrderListProps {}
const columns = [
  {
    title: "Order ID",
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "Total Products",
    dataIndex: "orderItems",
    key: "orderItems",
    render: (orderItems: any) => <Text>{orderItems.length} Products</Text>,
  },
  {
    title: "Order Value in $",
    dataIndex: "totalPrice",
    key: "totalPrice",
    render: (text: any) => <Text>$ {text}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "120px",
    render: (status: any, record: any) => <Tag>{status.toUpperCase()}</Tag>,
  },
];

export const OrderList: React.FC<IOrderListProps> = (
  props: IOrderListProps
) => {
  const {
    state: { list },
    actions: { getAll, updateOne },
  } = useOrder();

  React.useEffect(() => {
    getAll({});
  }, []);
  return (
    <Box>
      <PageHeader
        className="site-page-header"
        onBack={undefined}
        title="Orders"
        subTitle="List of all the Orders"
      />
      <Box padding="20px">
        <Table
          columns={columns}
          dataSource={list}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ target }: any) => {
                if (target.id === "actions") {
                  updateOne(record.id, { isActive: !record.isActive }, () => {
                    getAll({});
                  });
                }
              },
            };
          }}
        />
        ;
        <Flex justifyContent="end">
          {/* <Pagination defaultCurrent={1} total={50} /> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default OrderList;
