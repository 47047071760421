import { Box, Flex, Grid, Image } from "@chakra-ui/react";
import React from "react";
import { Switch, Route, Redirect } from "react-router";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import Verify from "./Verify";
// import Logo from "../../assets/images/smartbox-logo.png";

export const AuthLayout: React.FC<{}> = () => {
  return (
      <Grid
        height="100vh"
        backgroundColor="#eaeaed"
        gridAutoFlow="column"
        gridTemplateColumns="0.4fr 0.6fr"
      >
        <Box overflow="hidden">
          <Image
            src="https://images.unsplash.com/photo-1551377130-380f4fa9bb63?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&q=80"
            objectPosition="center"
            objectFit="cover"
            width="100%"
            height="100%"
          />
        </Box>
        <Flex height="100%" width="100%" justifyContent="center" align="center">
          <Flex
            alignItems="center"
            flexDirection="column"
            backgroundColor="n.100"
            padding="10"
            position="relative"
            borderRadius="base"
            width="60%"
            boxShadow="rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px"
          >
            <Image
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png"
              }
              width="120px"
              height="auto"
            />
            <Box width="90%" marginY="10px">
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route
                  exact
                  path="/verify/:type"
                  render={(props) => {
                    const { search } = props.location;
                    if (search.length) {
                      return <Verify {...props} />;
                    }
                    return <Redirect to="/page-not-found" />;
                  }}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/reset-password/:token"
                  component={ResetPassword}
                />
                <Redirect to="/login" />
              </Switch>
            </Box>
          </Flex>
        </Flex>
      </Grid>
  );
};

export default AuthLayout;
