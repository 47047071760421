import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { Button } from "../../atoms/Button";
import { FormikPasswordGroup } from "../../atoms/PasswordGroup";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import * as Yup from "yup";
import { Form, Formik } from "formik";
interface IResetPassword { }

export const ResetPassword: React.FC<IResetPassword> = (props: IResetPassword) => {
    return (
        <Box width="100%">
            <Formik
                validateOnChange={false}
                initialValues={{ password: "", passwordConfirmation: "" }}
                validationSchema={Yup.object({
                    password: Yup.string().required('Password is required'),
                    passwordConfirmation: Yup.string()
                        .test('passwords-match', 'Passwords must match', function (value) {
                            return this.parent.password === value
                        })
                })}
                onSubmit={(values, actions) => { }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Text color="n.800" marginBottom={3} fontWeight="bold">
                            Reset your password
                        </Text>
                        <Text
                            color="n.600"
                            marginBottom={4}
                            fontWeight="regular"
                            fontSize="14"
                        >
                            Please provide your new password.
                        </Text>

                        <FormikPasswordGroup
                            label="New password"
                            name="password"
                        ></FormikPasswordGroup>
                        <FormikPasswordGroup
                            label="Confirm Password"
                            name="passwordConfirmation"
                        ></FormikPasswordGroup>
                        <Flex justifyContent="end" marginTop="20px">
                            <Button htmlType="submit" type="primary" style={{ padding: "0 40px" }}>
                                Login
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ResetPassword;
