import { Box, Grid, Text, Flex, Input } from "@chakra-ui/react";
import { PageHeader, Upload, Image } from "antd";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { useProduct } from "./product.context";

import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import { FormikNumberFieldGroup } from "../../atoms/NumberFieldGroup";
import { FormikTextAreaGroup } from "../../atoms/TextAreaGroup";
import { Button } from "../../atoms/Button";
import { useHistory, useParams } from "react-router";
import { ImagetoBase64, uploadImage } from "../../utils/helper";
interface IProductDetailProps {
  isNew: boolean;
}

export const ProductDetail: React.FC<IProductDetailProps> = (
  props: IProductDetailProps
) => {
  const {
    state: { currentProduct },
    actions: { create, getOne, updateOne, clearCurrent },
  } = useProduct();
  
  const history = useHistory();
  const [initialValue, setInitialValue] = React.useState({
    name: "",
    description: "",
    price: "",
    availability: "",
    image:""
  });
  const [file, setFile]: any = React.useState(null);
  const [base64, setBase64]: any = React.useState(null);
  React.useEffect(() => {
    if (file && file.neededUpload) {
      ImagetoBase64(file.file).then((data) => setBase64(data));
    }
  }, [file]);

  const { isNew } = props;
  const { id }: any = useParams();
  React.useEffect(() => {
    if (!isNew) {
      getOne(id);
    }
  }, [isNew]);
  React.useEffect(() => {
    if (!isNew && currentProduct) {
      setInitialValue({
        availability: currentProduct.availability,
        description: currentProduct.description,
        name: currentProduct.name,
        price: currentProduct.price,
        image:currentProduct.image
      });
      if(currentProduct.image){
        setFile({file: currentProduct.image, neededUpload: false})
        setBase64(`${process.env.REACT_APP_IMAGE_ENDPOINT}${currentProduct.image}`)
      }
    }
  }, [currentProduct]);
  return (
    <Box>
      <PageHeader
        onBack={() => {
          clearCurrent();
          history.goBack();
        }}
        title={
          isNew ? "New Product" : currentProduct ? currentProduct.name : ""
        }
        subTitle={isNew ? "Create a new Product." : "Edit the product"}
      />
      <Box padding="20px">
        <Text fontSize="14px" color="n.400">
          Please provide the following information to create a new product
        </Text>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            price: Yup.number()
              .typeError("Price must be number")
              .required("Required"),
            description: Yup.string().required("Required"),
            availability: Yup.number()
              .typeError("Availability must be number")
              .required("Required"),
          })}
          onSubmit={async (values, actions) => {
            if(file.neededUpload){
              const uploadRes:any = await uploadImage(file.file)
              values.image = uploadRes.filePath
            }
            if (isNew) {
              create(values, (res) => {
                history.replace(`/products/${res.id}`);
              });
            } else {
              updateOne(id, values, (res) => {
                history.replace(`/products/${res.id}`);
              });
            }
           
          }}
        >
          {({ values, setFieldValue }) => (
            <Box marginTop="40px">
              <Form>
                <Grid
                  width="100%"
                  gridTemplateColumns={"0.5fr 0.5fr"}
                  minHeight="200px"
                >
                  <Box paddingRight="20px">
                    <FormikTextFieldGroup
                      label="Name"
                      name="name"
                    ></FormikTextFieldGroup>
                    <Flex gridGap="20px">
                      <FormikTextFieldGroup
                        label="Price"
                        name="price"
                      ></FormikTextFieldGroup>
                      <FormikTextFieldGroup
                        label="Available Quantity"
                        name="availability"
                      ></FormikTextFieldGroup>
                    </Flex>
                  </Box>
                  <Flex
                    marginLeft="60px"
                    alignItems="center"
                    flexDirection="column"
                  >
                    {file ? (
                      <Box>
                        {base64 ? (
                          <Image width={200} height={200} src={base64} />
                        ) : (
                          <Image width={200} height={200} preview={false} />
                        )}
                        <Flex
                          width="auto"
                          height="50px"
                          border="1px dashed #a0a0a0"
                          alignItems="center"
                          justifyContent="center"
                          position="relative"
                        >
                          <Text fontSize="14px" fontWeight="400" color="black">
                            Change Image
                          </Text>
                          <Input
                            type="file"
                            accept="image/*"
                            border="none"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            opacity={0}
                            cursor="pointer"
                            onChange={({ target }: any) => {
                              setBase64(null);
                              setFile({
                                file: target && target.files[0],
                                neededUpload: true,
                              });
                            }}
                          />
                        </Flex>
                      </Box>
                    ) : (
                      <Flex
                        width="200px"
                        height="200px"
                        border="1px dashed #a0a0a0"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        <Text fontSize="14px" fontWeight="400" color="black">
                          Upload Image
                        </Text>
                        <Input
                          type="file"
                          accept="image/*"
                          border="none"
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          opacity={0}
                          cursor="pointer"
                          onChange={({ target }: any) => {
                            setBase64(null);
                            setFile({
                              file: target && target.files[0],
                              neededUpload: true,
                            });
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Grid>
                {/* Description */}
                <Box>
                  <FormikTextAreaGroup
                    label="Description"
                    name="description"
                    showCount
                    maxLength={1000}
                    autoSize={{ minRows: 5, maxRows: 50 }}
                  />
                </Box>
                {/* Buttons */}
                <Flex
                  width="100%"
                  marginTop="40px"
                  justifyContent="right"
                  gridGap="20px"
                >
                  <Button type="default">Cancel</Button>
                  <Button type="primary" htmlType="submit">
                    {isNew ? "Save" : "Update"}
                  </Button>
                </Flex>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ProductDetail;
