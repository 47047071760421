import { Redirect, Route, Switch } from "react-router";
import { CustomerProvider } from "./customer.context";
import ProductDetail from "./CustomerDetail";
import ProductList from "./CustomerList";
interface ICustomerCoreProps {}

export const CustomerCore: React.FC<ICustomerCoreProps> = (
  props: ICustomerCoreProps
) => {
  return (
    <CustomerProvider>
      <Switch>
        <Route exact path="/customers" component={ProductList} />
        <Route
          exact
          path="/customers/new"
          render={() => <ProductDetail isNew={true} />}
        />
        <Route
          exact
          path="/customers/:id"
          render={() => <ProductDetail isNew={false} />}
        />
        <Redirect to="/customers" />
      </Switch>
    </CustomerProvider>
  );
};

export default CustomerCore;
