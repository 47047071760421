import { Box, Text, Flex } from "@chakra-ui/react";
import * as React from "react";
import { Button } from "../../atoms/Button";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import OtpInput from "react-otp-input";
import { useLocation, useParams } from "react-router";
import { parseQueryStrings } from "../../utils/service";
import { useSession } from "./auth.contex";

interface IVerify { }

const sleep = (interval: number) =>
    new Promise((resolve) => setTimeout(() => resolve(true), interval));

export const Verify: React.FC<IVerify> = (props: IVerify) => {
    const { search } = useLocation();
    const params:any = useParams();
    const [email, setEmail] = React.useState("");
    const [canResend, setResend] = React.useState(false);
    const [timerValue, setTimerValue] = React.useState(30);

    const runner = async () => {
        let i = 30;
        setTimerValue(i);
        while (i > 0) {
            await sleep(1000);
            setTimerValue(--i);
        }
    };
    React.useEffect(() => {
        const { t }: any = parseQueryStrings(search);
        if (t) {
            setEmail(atob(t))
        }
    }, [search])
    React.useEffect(() => {
        if (timerValue === 0) {
            setResend(true)
        }
    }, [timerValue])

    React.useEffect(() => {
        if (canResend === false) {
            runner();
        }
    }, [canResend]);

    const { actions: { resendOTP, verifyOTP } } = useSession()
    return (
        <Box width="100%">
            <Formik
                validateOnChange={false}
                initialValues={{ verificationCode: "" }}
                validationSchema={Yup.object({
                    verificationCode: Yup.string().test('len', 'OPT is required.', (val: any) => val && val.length === 6)
                })}
                onSubmit={(values, actions) => { 
                    verifyOTP({...values, email},params.type);
                }}
            >
                {({ values, setFieldValue, errors }) => (
                    <Form>
                        <Text color="n.800" marginBottom={6} fontWeight="bold">
                            Verify OTP
                        </Text>
                        <Text
                            color="n.600"
                            marginBottom={1}
                            fontWeight="regular"
                            fontSize="14"
                        >
                            A one time password has been sent to your registered email. Please
                            enter it below.
                        </Text>
                        <Flex justifyContent="center" flexDirection="column" alignItems="center" marginTop="20px" marginBottom="40px">
                            <OtpInput
                                value={values.verificationCode}
                                onChange={(otp: any) => setFieldValue("verificationCode", otp.toString())}
                                numInputs={6}
                                isInputNum
                                containerStyle={{
                                    justifyContent: "center",
                                }}
                                inputStyle={{
                                    color: "#101840",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    width: "2em",
                                    height: "3em",
                                    margin: "0px 10px",
                                    border: "1px solid #d9d9d9",
                                }}
                                separator={<span style={{ color: "black" }}>-</span>}
                            />
                            {errors.verificationCode && <Text marginTop="10px" color="red.500" fontSize="14px" fontWeight="400">OTP is required</Text>}
                        </Flex>
                        <Flex justifyContent="space-between" marginTop="20px" alignItems="center">
                            <Box>
                                <Button type="text" style={{ fontSize: "12px" }} disabled={!canResend} onClick={() => { setResend(false); resendOTP({ email }) }}>
                                    Din't Recieve OTP? Send again.
                                </Button>
                                {!canResend && <Text
                                    color="n.600"
                                    marginBottom={1}
                                    fontWeight="regular"
                                    fontSize="10"
                                    textAlign="center"
                                >
                                    Enables in {timerValue} sec
                                </Text>}
                            </Box>
                            <Button htmlType={'submit'} type="primary" style={{ padding: "0 40px" }}>
                                Verify
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default Verify;
