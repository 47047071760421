import { Box, Text } from "@chakra-ui/react";

interface IDashboardProps {}

export const Dashboard: React.FC<IDashboardProps> = (
  props: IDashboardProps
) => {
  return (
    <Box>
      <Text color="n.900">Dashboard</Text>
    </Box>
  );
};

export default Dashboard;
