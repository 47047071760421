import { BrowserRouter as Router } from "react-router-dom";
import "./App.less";
import theme from "./chakra-theme";
import { ChakraProvider, extendTheme, ColorModeScript } from "@chakra-ui/react";
import { RouteMan } from "./routes/Routes";
import { AuthProvider } from "./routes/routeContext";
import { SessionProvider } from "./pages/auth/auth.contex";

const extendedTheme = extendTheme({
  ...theme
});

const App = () => (
  <div className="App" style={{background:"#fff"}}>
    <ChakraProvider  theme={extendedTheme}>
    <ColorModeScript initialColorMode={'light'} />
      <AuthProvider>
        <SessionProvider>
        <Router>
          <RouteMan />
        </Router>
        </SessionProvider>
      </AuthProvider>
    </ChakraProvider>
  </div>
);

export default App;
