import { Input, InputProps } from "antd";
import {
  Box,
  Text,
} from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";
interface PasswordGroupProps extends InputProps {
  label: string;
  error?: any;
}

export interface FormikPasswordGroupProps extends PasswordGroupProps {
  name: string;
}

export const PasswordGroup: React.FC<PasswordGroupProps> = ({
  label,
  error,
  ...props
}: PasswordGroupProps) => {
  const theme: any = useTheme();
  return (
    <Box position="relative" py="10px" width="100%">
       <Text fontSize="p200" marginBottom={2} fontWeight={100} textColor="n.700" textTransform="capitalize">{label}</Text>
      <Input.Password
        {...props}
      ></Input.Password>
      {error && (
        <Text marginTop="5px" color="red.500" fontSize="14px" fontWeight="400">
          {error}
        </Text>
      )}
    </Box>
  );
};

export const FormikPasswordGroup: React.FC<FormikPasswordGroupProps> = ({
  name,
  ...props
}: FormikPasswordGroupProps) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || "";
  return <PasswordGroup {...field} {...props} error={error} />;
};
