import { useReducer } from "react";
import { container } from "../../utils/container";
import { APIService } from "../../utils/service/request.service";
import { message } from "antd";
import { CookieStorage, LocalStorage } from "../../utils/storage";

const ApiRoutes = {
  base: "/stores",
};

export type StateType = {
  currentSetting: any,
};

const initialState: StateType = {
  currentSetting:null,
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_ONE_SUCCESS":
      return {
        ...state,
        currentSetting: payload
      };
    default:
      return state;
  }
};

export const { useContext: useSetting, Provider: SettingProvider } = container(
  () => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    
    const getOne = (id: string, callback?: (data: any) => void) => {
      message.loading({ content: 'loading...', key: "settings" })
      APIService.get(`${ApiRoutes.base}/${id}/settings`)
        .then(({ data }: any) => {

          dispatch({ type: "GET_ONE_SUCCESS", payload: data.storeSetting });
          if (typeof callback === "function") {
            callback(data.storeSetting);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (id: string, data: any, callback?: (data: any) => void) => {
      console.log(id, data)
      message.loading({ content: 'updating...', key: "settings" })
      APIService.put(`${ApiRoutes.base}/${id}/settings`, data)
        .then(({ data }: any) => {
          console.log(data)
          dispatch({ type: "GET_ONE_SUCCESS", payload: data.storeSetting });
          message.success({ content: "updates successfully", key: "settings" })
          if (typeof callback === "function") {
            callback(data.storeSetting);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    return {
      state,
      actions: {
        getOne,
        updateOne,
        clearCurrent: () => dispatch({ type: "CLEAR", payload: {} })
      },
    };
  }
);
