import { Box, Grid, Text, Flex, Input } from "@chakra-ui/react";
import { PageHeader, Tag, Image, Input as AntInput } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { useSetting } from "./settings.context";
import * as Yup from "yup";
import { FormikTextFieldGroup } from "../../atoms/TextFieldGroup";
import { Button } from "../../atoms/Button";
import { useHistory, useParams } from "react-router";
import { ImagetoBase64, uploadImage } from "../../utils/helper";
import { useSession } from "../auth/auth.contex";
import { FormikTextAreaGroup } from "../../atoms/TextAreaGroup";
interface IStoreInformationProps {
  store: string;
}

export const StoreInformation: React.FC<IStoreInformationProps> = (
  props: IStoreInformationProps
) => {
  const {
    state: { currentSetting },
    actions: { getOne, updateOne },
  } = useSetting();
  const {
    state: { currentStore },
  } = useSession();
  const history = useHistory();
  const [initialValue, setInitialValue]: any = React.useState({
    title: "",
    keyword: "",
    description: "",
    keywords: [],
    logo: "",
  });
  const [file, setFile]: any = React.useState(null);
  const [base64, setBase64]: any = React.useState(null);
  React.useEffect(() => {
    if (file && file.neededUpload) {
      ImagetoBase64(file.file).then((data) => setBase64(data));
    }
  }, [file]);

  React.useEffect(() => {
    getOne(currentStore);
  }, []);
  React.useEffect(() => {
    if(!currentSetting){
      return;
    }
    setInitialValue({
      ...currentSetting,
    });
    if (currentSetting.logo) {
      setFile({ file: currentSetting.logo, neededUpload: false });
      setBase64(
        `${process.env.REACT_APP_IMAGE_ENDPOINT}${currentSetting.logo}`
      );
    }
  }, [currentSetting]);
  return (
    <Box>
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={Yup.object({
          title: Yup.string().required("Required"),
        })}
        onSubmit={async (values, actions) => {
          if (file && file.neededUpload) {
            const uploadRes: any = await uploadImage(file.file, {
              folder: `/store/${currentStore}/`,
              maxFileSize: 1,
            });
            values.logo = uploadRes.filePath;
          }
          updateOne(currentStore, values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Box marginTop="40px">
            <Form>
              <Grid width="100%" gridTemplateColumns={"0.5fr 0.5fr"}>
                <Box paddingRight="20px">
                  {/* Title */}
                  <FormikTextFieldGroup
                    label="Page Title"
                    name="title"
                  ></FormikTextFieldGroup>
                  <FormikTextAreaGroup
                    label="Page Description"
                    name="description"
                    showCount
                    maxLength={120}
                    autoSize={{ minRows: 5 }}
                  />
                  {/* Keywords */}
                  <Box position="relative" width="100%" marginY={3}>
                    <Text
                      fontSize="p200"
                      marginY={1}
                      fontWeight={100}
                      textColor="n.700"
                      textTransform="capitalize"
                    >
                      Keywords
                    </Text>

                    <AntInput
                      onChange={({ target }) =>
                        setFieldValue("keyword", target.value)
                      }
                      value={values.keyword}
                      addonAfter={
                        <Box
                          paddingX="10px"
                          color="n.900"
                          fontWeight="700"
                          cursor="pointer"
                          onClick={() => {
                            if (
                              values.keyword &&
                              values.keyword.trim().length
                            ) {
                              const v = [...values.keywords, values.keyword];
                              setFieldValue("keywords", v);
                              setFieldValue("keyword", "");
                            }
                          }}
                        >
                          Add
                        </Box>
                      }
                    />
                    <Flex flexWrap="wrap">
                      {(values.keywords || []).map((x: any) => (
                        <Tag
                          style={{ marginTop: 10 }}
                          key={x}
                          closable
                          onClose={() => {
                            console.log(x);
                          }}
                        >
                          {x}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                </Box>
                <Flex
                  marginLeft="60px"
                  alignItems="center"
                  flexDirection="column"
                >
                  {file ? (
                    <Box>
                      {base64 ? (
                        <Image width={200} height={100} src={base64} />
                      ) : (
                        <Image width={200} height={100} preview={false} />
                      )}
                      <Flex
                        width="auto"
                        height="50px"
                        border="1px dashed #a0a0a0"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        <Text fontSize="14px" fontWeight="400" color="black">
                          Change Logo
                        </Text>
                        <Input
                          type="file"
                          accept="image/*"
                          border="none"
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          opacity={0}
                          cursor="pointer"
                          onChange={({ target }: any) => {
                            setBase64(null);
                            setFile({
                              file: target && target.files[0],
                              neededUpload: true,
                            });
                          }}
                        />
                      </Flex>
                    </Box>
                  ) : (
                    <Flex
                      width="200px"
                      height="100px"
                      border="1px dashed #a0a0a0"
                      alignItems="center"
                      justifyContent="center"
                      position="relative"
                    >
                      <Text fontSize="14px" fontWeight="400" color="black">
                        Upload Logo
                      </Text>
                      <Input
                        type="file"
                        accept="image/*"
                        border="none"
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        opacity={0}
                        cursor="pointer"
                        onChange={({ target }: any) => {
                          setBase64(null);
                          setFile({
                            file: target && target.files[0],
                            neededUpload: true,
                          });
                        }}
                      />
                    </Flex>
                  )}
                </Flex>
              </Grid>
              {/* Description */}
              {/* Buttons */}
              <Flex
                width="100%"
                marginTop="20px"
                justifyContent="left"
                gridGap="20px"
              >
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Flex>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default StoreInformation;
