import { Redirect, Route, Switch } from "react-router";
import { OrderProvider } from "./order.context";
import ProductDetail from "./OrderDetail";
import ProductList from "./OrderList";
interface IOrderCoreProps {}

export const OrderCore: React.FC<IOrderCoreProps> = (
  props: IOrderCoreProps
) => {
  return (
    <OrderProvider>
      <Switch>
        <Route exact path="/orders" component={ProductList} />
        <Route
          exact
          path="/orders/new"
          render={() => <ProductDetail isNew={true} />}
        />
        <Route
          exact
          path="/orders/:id"
          render={() => <ProductDetail isNew={false} />}
        />
        <Redirect to="/orders" />
      </Switch>
    </OrderProvider>
  );
};

export default OrderCore;
