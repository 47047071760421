import { Redirect, Route, Switch } from "react-router";
import { ProductProvider } from "./product.context";
import ProductDetail from "./ProductDetail";
import ProductList from "./ProductList";
interface IProductCoreProps {}

export const ProductCore: React.FC<IProductCoreProps> = (
  props: IProductCoreProps
) => {
  return (
    <ProductProvider>
      <Switch>
        <Route exact path="/products" component={ProductList} />
        <Route exact path="/products/new" render={()=><ProductDetail isNew={true}/>} />
        <Route exact path="/products/:id" render={()=><ProductDetail isNew={false}/>}  />
        <Redirect to="/products" />
      </Switch>
    </ProductProvider>
  );
};

export default ProductCore;
