import { Box, Flex, Text,  } from "@chakra-ui/react";
import { Table, Tag, Space, Pagination, PageHeader, Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Button";
import { useProduct } from "./product.context";
interface IProductListProps {}
const columns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    width: "120px",
    render: (text: any, item: any) => (
      <Image src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${text}`}></Image>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, item: any) => (
      <Link to={`/products/${item.id}`}>{text}</Link>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text: any) => <Text>{text}</Text>,
  },
  {
    title: "Price in $",
    dataIndex: "price",
    key: "price",
    width: "120px",
    render: (text: any) => <Text>$ {text}</Text>,
  },
  {
    title: "Status",
    key: "isActive",
    width: "120px",
    render: (status: any, record: any) => (status ? "Active" : "In Active"),
  },
  {
    title: "Action",
    key: "action",
    width: "120px",
    render: (text: any, record: any) => (
      <Space size="middle">
        <Button type="link" id="actions">
          {!record.isActive ? (
            <Text color="green.500" id="actions" fontWeight="700">
              Activate
            </Text>
          ) : (
            <Text color="red.500" id="actions" fontWeight="700">
              De Activate
            </Text>
          )}
        </Button>
      </Space>
    ),
  },
];

export const ProductList: React.FC<IProductListProps> = (
  props: IProductListProps
) => {
  const {
    state: { list },
    actions: { getAll, updateOne },
  } = useProduct();

  React.useEffect(() => {
    getAll({});
  }, []);
  return (
    <Box>
      <PageHeader
        className="site-page-header"
        onBack={undefined}
        title="Products"
        subTitle="List of all the Products"
        extra={[
          <Link to="/products/new">
            <Button key="1" type="primary">
              Create New
            </Button>
          </Link>,
        ]}
      />
      <Box padding="20px">
        <Table
          columns={columns}
          dataSource={list}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ target }: any) => {
                if (target.id === "actions") {
                  updateOne(record.id, { isActive: !record.isActive },()=>{
                    getAll({})
                  });
                }
              },
            };
          }}
        />
        ;
        <Flex justifyContent="end">
          {/* <Pagination defaultCurrent={1} total={50} /> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default ProductList;
